import { createContext, memo } from 'react';
import { useLinksQuery } from '@/graphql/generated';
import { useCustomers } from '../customers';
import { DjpLinksProvider } from 'dj-pages-react';
import { useApolloClient } from '@apollo/client';

export interface Links {
  PrivacyPolicy: string;
  YapilyLegal: string;
  DojoSupportEmail: string;
  YoulendPrivacyPolicy: string;
  YoulendSupportEmail: string;
  CIFASFairProcessingNotice: string;
  YoulendTermsAndConditions: string;
  YoulendPaymentAccountTerms: string;
}

export interface LinksContextType {
  loading: boolean;
  links?: Links;
}

export const LinksContext = createContext<LinksContextType>({
  loading: false,
  links: {} as Links,
});

const LinksProvider = ({ children }): JSX.Element => {
  const client = useApolloClient();
  const { loading: loadingCustomer, customer } = useCustomers();

  // fall back to GB if customer is not loaded yet
  const marketId = customer?.marketId ?? 'GB';

  const { loading, data, error } = useLinksQuery({ skip: loadingCustomer, variables: { marketId: marketId } });

  const links = data?.links?.reduce((accum, link) => {
    accum[link.id] = link.value;
    return accum;
  }, {} as Links);

  return (
    <LinksContext.Provider value={{ loading, links }}>
      <DjpLinksProvider marketId={marketId} client={client as any}>
        {children}
      </DjpLinksProvider>
    </LinksContext.Provider>
  );
};

export default memo(LinksProvider);
