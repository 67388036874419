import { memo, useMemo } from 'react';
import { Permissions } from '@/utils/permissions';
import { DjIconConstruction, DjIconCredit_card, DjIconTransfer } from '@paymentsense/dj-components-react';
import { useCustomers } from '@/providers/customers';
import { Routes } from '@/utils/routes';
import NavFolder from '../navFolder/navFolder';
import NavLink from '../navLink/navLink';
import Authorization from '../authorization/authorization';
import useShowHome from '@/hooks/useShowHome';
import useShowBusinessFunding from '@/hooks/useShowBusinessFunding';
import useShowQueueBooking from '@/hooks/useShowQueueBooking';
import { navigationItemsAnalyticMap } from '@/config/analytics';
import useShowPayByQr from '@/hooks/useShowPayByQr';
import useShowNewQueueBooking from '@/hooks/useShowNewQueueBooking';
import useShowNewOnlinePayments from '@/hooks/useShowNewOnlinePayments';
import { DJHomeIcon } from '../navLink/homeIcon';
import useShouldShowDojoCardInterest from '@/hooks/useShouldShowDojoCardInterest';
import useShowCardMachines from '@/hooks/useShowCardMachines';

const NavigationItems = () => {
  const { customer } = useCustomers();
  const { locations } = customer ?? {};
  const { shouldShowHome } = useShowHome();
  const shouldShowOnlinePayments = useMemo(() => locations?.some((l) => l.remotePaymentAppConfig?.portal?.enabled), [locations]);
  const shouldShowNewOnlinePayments = useShowNewOnlinePayments();
  const shouldShowPayments = useMemo(() => locations?.some((l) => l.remotePaymentAppConfig?.paymentLinks?.enabled), [locations]);
  const shouldShowBusinessFunding = useShowBusinessFunding();
  const shouldShowOldQueuesBooking = useShowQueueBooking();
  const shouldShowNewQueuesBooking = useShowNewQueueBooking();
  const shouldShowPayByQr = useShowPayByQr();
  const shouldShowInsightsNewChip = new Date() < new Date(2024, 4, 18);
  const { shouldShowDojoCardInterest } = useShouldShowDojoCardInterest();
  const { shouldShowCardMachines } = useShowCardMachines('0');

  return (
    <>
      <Authorization key="home" shouldShow={shouldShowHome}>
        <NavLink
          analyticKey={navigationItemsAnalyticMap.Home}
          href={Routes.home}
          icon={<DJHomeIcon fillColour="alpha60" />}
          text="global:global.navigation.home_menu_item"
        />
      </Authorization>
      <Authorization key="transactions" permission={Permissions.transactionsAllView}>
        <NavLink
          analyticKey={navigationItemsAnalyticMap.Transactions}
          href={Routes.transactions}
          icon={<DjIconCredit_card fillColour="alpha60" />}
          text="global:global.navigation.transactions_menu_item"
        />
      </Authorization>
      <NavFolder
        key="tools"
        analyticKey={navigationItemsAnalyticMap.Tools}
        icon={<DjIconConstruction fillColour="alpha60" />}
        text="global:global.navigation.tools_menu_item"
      >
        {shouldShowCardMachines && (
          <Authorization key="card-machines" permission={Permissions.cardMachinesDetailsView}>
            <NavLink
              analyticKey={navigationItemsAnalyticMap['Card machines']}
              href={Routes.cardMachines}
              sub
              text="global:global.navigation.card_machines_menu_item"
            />
          </Authorization>
        )}
        <Authorization key="tap-devices" permission={Permissions.tapDevicesDetailsView}>
          <NavLink
            analyticKey={navigationItemsAnalyticMap['Tap to Pay devices']}
            href={Routes.tap}
            sub
            text="global:global.navigation.tap_to_pay_devices_menu_item"
          />
        </Authorization>
        {shouldShowOnlinePayments && shouldShowNewOnlinePayments ? (
          <NavLink
            analyticKey={navigationItemsAnalyticMap['Online payments']}
            href={Routes.newOnlinePayments}
            sub
            text="global:global.navigation.online_payments_menu_item"
          />
        ) : shouldShowOnlinePayments ? (
          <NavLink
            analyticKey={navigationItemsAnalyticMap['Online payments']}
            href={Routes.onlinePayments}
            sub
            text="global:global.navigation.online_payments_menu_item"
          />
        ) : null}
        <Authorization key="payments" permission={Permissions.paymentLinksView} shouldShow={shouldShowPayments}>
          <NavLink analyticKey={navigationItemsAnalyticMap.Payments} href={Routes.payments} sub text="global:global.navigation.payments_menu_item" />
        </Authorization>
        <Authorization key="insights" permission={Permissions.businessInsightsView}>
          <NavLink
            analyticKey={navigationItemsAnalyticMap.Insights}
            href={Routes.insights}
            sub
            text="global:global.navigation.insights_menu_item"
            showNew={shouldShowInsightsNewChip}
          />
        </Authorization>
        <Authorization key="business-funding" permission={Permissions.businessFundingView} shouldShow={shouldShowBusinessFunding}>
          <NavLink
            analyticKey={navigationItemsAnalyticMap['Business funding']}
            href={Routes.businessFunding}
            sub
            text="global:global.navigation.business_funding_menu_item"
          />
        </Authorization>
        {shouldShowNewQueuesBooking ? (
          <NavLink
            analyticKey={navigationItemsAnalyticMap['Virtual queues &amp; bookings']}
            href={Routes.newQueuesBooking}
            sub
            text="global:global.navigation.queue_booking_menu_item"
          />
        ) : (
          shouldShowOldQueuesBooking && (
            <NavLink
              analyticKey={navigationItemsAnalyticMap['Virtual queues &amp; bookings']}
              href={Routes.queuesBooking}
              sub
              text="global:global.navigation.queue_booking_menu_item"
            />
          )
        )}
        <Authorization key="pay-by-qr" shouldShow={shouldShowPayByQr}>
          <NavLink analyticKey={navigationItemsAnalyticMap['Pay by QR']} href={Routes.payByQr} sub text="global:global.navigation.pay_by_qr_menu_item" />
        </Authorization>

        <Authorization key="dojo-card" shouldShow={shouldShowDojoCardInterest}>
          <NavLink analyticKey={navigationItemsAnalyticMap['Dojo Card']} href={Routes.dojoCard} sub text="global:global.navigation.dojo_card" />
        </Authorization>
      </NavFolder>
      <Authorization key="transfers-booking" permission={Permissions.transfersAllView}>
        <NavLink
          analyticKey={navigationItemsAnalyticMap.Transfers}
          href={Routes.transfers}
          icon={<DjIconTransfer fillColour="alpha60" />}
          text="global:global.navigation.transfers_menu_item"
        />
      </Authorization>
    </>
  );
};

export default memo(NavigationItems);
