export const AnalyticsCategory = {
  account: 'account',
  accountDetails: 'accountDetails',
  accountingIntegrations: 'accountingIntegrations',
  accountNavigation: 'accountNavigation',
  accountSettings: 'accountSettings',
  appInsights: 'appInsights',
  billing: 'billing',
  cardMachine: 'cardMachines',
  machineActivation: 'machineActivation',
  cardMachineAlias: 'aliasTerminal',
  cardMachinesOrdering: 'cardMachinesOrdering',
  digitalReceipt: 'digitalReceipt',
  dojoAdvanced: 'dojoAdvanceAccount',
  error: 'error',
  homepage: 'homepage',
  location: 'aliasLocation',
  locationAlias: 'alias_location',
  locations: 'locations',
  login: 'login',
  myDetails: 'myDetails',
  navigation: 'navigation',
  paymentLinks: 'paymentLinks',
  payments: 'payments',
  pciCompliance: 'pciCompliance',
  permissionToShare: 'permissionToShare',
  productSignUp: 'productSignUp',
  referAFriend: 'referAFriend',
  remotePayments: 'remotePayments',
  signIn: 'signIn',
  tapToPayDevices: 'tapToPayOniPhone',
  teamMembers: 'teamMembers',
  teams: 'teams',
  tools: 'tools',
  transactionDetails: 'transactionDetails',
  transactionDetailsContent: 'transactionDetailsContent',
  transactionFilters: 'filters',
  transactions: 'transactions',
  transfers: 'transfers',
  virtualQueuesAndBookings: 'virtualQueuesAndBookings',
  virtualTerminalPayment: 'virtualTerminalPayment',
  payByBankPayment: 'payByBankPayment',
  onlinePayments: 'onlinePayments',
  reviewPurchase: 'reviewPurchase',
  dojoCard: 'dojoCard',
  onboardingProductTour: 'onboardingProductTour',
};

export const AnalyticsAction = {
  addedQuantity: 'addedQuantity',
  addedTeamMember: 'addedTeamMember',
  appliedAllFilters: 'appliedAllFilters',
  appliedBankingWindowFilter: 'appliedBankingWindowFilter',
  appliedCardFilter: 'appliedCardFilter',
  appliedCardMachineFilter: 'appliedCardMachineFilter',
  appliedCardMachines: 'appliedCardMachines',
  appliedCardScheme: 'appliedCardScheme',
  appliedDateRange: 'appliedDateRange',
  appliedDateRangeFilter: 'appliedDateRangeFilter',
  appliedLocation: 'appliedLocation',
  appliedLocationFilter: 'appliedLocationFilter',
  appliedPaymentMethod: 'appliedPaymentMethod',
  appliedPaymentSourceFilter: 'appliedPaymentSourceFilter',
  appliedPermissions: 'appliedPermissions',
  appliedStatusFilter: 'appliedStatusFilter',
  appliedTimeRange: 'appliedTimeRange',
  appliedTimeRangeFilter: 'appliedTimeRangeFilter',
  appliedTransactionOutcome: 'appliedTransactionOutcome',
  appliedTransactionType: 'appliedTransactionType',
  cardMachines: 'cardMachines',
  cardMachineSearch: 'cardMachineSearch',
  changedPaymentLinkStatus: 'changedPaymentLinkStatus',
  changedVirtualTerminalPaymentStatus: 'changedVirtualTerminalPaymentStatus',
  checkedAccountSection: 'checkedAccountSection',
  checkedDetails: 'checkedDetails',
  checkedLocationRemotePayments: 'checkedLocationRemotePayments',
  checkedRemotePaymentsFaqs: 'checkedRemotePaymentFAQs',
  checkedSpecificLocation: 'checkedSpecificLocation',
  checkedTransferDetails: 'checkedTransferDetails',
  checkedTransferTimeDetail: 'checkedTransferTimeDetail',
  checkedTransferTimeDetails: 'checkedTransferTimeDetail',
  chooseTerminal: 'choose_terminal',
  clickedAcceptAndSubmit: 'clickedAcceptAndSubmit',
  clickedAddTeamsMember: 'clickedAddTeamsMember',
  clickedAllFilters: 'clickedAllFilters',
  clickedApplyNow: 'clickedApplyNow',
  clickedApplyViaMobileApp: 'clickedApplyViaMobileApp',
  clickedBack: 'clickedBack',
  clickedBinIcon: 'clickedBinIcon',
  clickedCancel: 'clickedCancel',
  clickedClose: 'clickedClose',
  clickedCantFindAContact: 'clickedCantFindAContact',
  clickedCantFindAnAddress: 'clickedCantFindAnAddress',
  clickedClearFilters: 'clickedClearFilters',
  clickedConfirm: 'clickedConfirm',
  clickedContactUs: 'clickedContactUs',
  clickedContinue: 'clickedContinue',
  clickedContinueAnyway: 'clickedContinueAnyway',
  clickedContinueOrder: 'clickedContinueOrder',
  clickedCopyLink: 'clickedCopyLink',
  clickedCta: 'clickedCta',
  clickedDone: 'clickedDone',
  clickedMaybeLater: 'clickedMaybeLater',
  clickedEdit: 'clickedEdit',
  clickedExitWithoutSaving: 'clickedExitWithoutSaving',
  clickedExport: 'clickedExport',
  clickedFaqs: 'clicked_faqs',
  clickedFilters: 'clickedFilters',
  clickedHelpIcon: 'clickedHelpIcon',
  clickedLink: 'clickedLink',
  clickedNext: 'clickedNext',
  clickedStartTheTour: 'clickedStartTheTour',
  clickedPlaceOrder: 'clickedPlaceOrder',
  clickedRemoveTeamsMember: 'clickedRemoveTeamsMember',
  clickedRetry: 'clickedRetry',
  clickedRole: 'clickedRole',
  clickedSearch: 'clickedSearch',
  clickedSendReceipt: 'clickedSendReceipt',
  clickedSettings: 'clickedSettings',
  clickedTrackPackage: 'clickedTrackPackage',
  clickedTransactionDetails: 'clicked_transaction_details',
  clickedViewQRCode: 'clickedViewQRCode',
  clickedYesExit: 'clickedYesExit',
  clicksBackToCardMachines: 'clicksBackToCardMachines',
  clicksViewOrders: 'clicksViewOrders',
  closedAccountSwitcher: 'closedAccountSwitcher',
  closedBottomMenu: 'closedBottomMenu',
  closedFAQ: 'closedFAQ',
  closedModal: 'closedModal',
  closedPageCorner: 'closedPageCorner',
  closedSidePanel: 'closedSidePanel',
  completedCompliance: 'completed_compliance',
  confirmedCompliance: 'confirmed_compliance',
  confirmedPermissions: 'confirmedPermissions',
  confirmedResetLink: 'confirmedResetLink',
  contactRemoved: 'contactRemoved',
  contactUs: 'contact_us',
  copiedJWTToken: 'copiedJWTToken',
  copiedTrackingNumber: 'copiedTrackingNumber',
  copiedQRCodeLink: 'copiedQRCodeLink',
  copyPaymentLinkWeb: 'copiedPaymentLinkWeb',
  createPaymentLink: 'createPaymentLink',
  createVirtualTerminal: 'createVirtualTerminal',
  dateRangeFilter: 'filteredDateRange',
  dateRangeFilterSelection: 'selectedDateRangeFilter',
  deviceDetails: 'deviceDetails',
  deviceList: 'deviceList',
  dismissedTimeInfoBanner: 'dismissedTimeInfoBanner',
  downloadedBillingInvoice: 'downloadedBillingInvoice',
  downloadedBillingInvoiceConfirmed: 'downloadedBillingInvoiceConfirmed',
  downloadedBillingInvoices: 'downloadedBillingInvoices',
  downloadedLink: 'downloadedLink',
  downloadedPaymentLinkQRCode: 'downloadedPaymentLinkQRCode',
  downloadedPdfDatasecurity: 'downloaded_pdf_datasecurity',
  downloadedPdfP2pManual: 'downloaded_pdf_P2PEmanual',
  editLocationInfo: 'edit_location_info',
  editTerminalInfo: 'edit_terminal_info',
  exportedTransactions: 'exportedTransactions',
  firstPaymentPage: 'firstPaymentPage',
  generatedVirtualTerminalPayment: 'generatedVirtualTerminalPayment',
  generatedPayByBankPayment: 'generatedPayByBankPayment',
  generatePaymentLink: 'generatedPaymentLink',
  hasFormErrors: 'hasFormErrors',
  initiatePayByBank: 'initiatePayByBank',
  initiatePaymentLink: 'initiatePaymentLink',
  initiateVirtualTerminal: 'initiateVirtualTerminal',
  learnMoreFaqs: 'clickedLearnMoreFAQs',
  locationFilter: 'filteredLocation',
  locationSearch: 'locationSearch',
  loggedOut: 'loggedOut',
  openedAccountSwitcher: 'openedAccountSwitcher',
  openedBottomMenu: 'openedBottomMenu',
  openedFAQ: 'openedFAQ',
  openedPaymentLink: 'openedPaymentLink',
  openedPaymentLinkQRCode: 'openedPaymentLinkQRCode',
  openedPaymentsCollect: 'openedPaymentsCollect',
  openedPaymentsRelease: 'openedPaymentsRelease',
  openedSubtotal: 'openedSubtotal',
  orderDetails: 'orderDetails',
  orderFilterLocation: 'orderFilterLocation',
  orderFilters: 'orderFilters',
  orderFilterStatus: 'orderFilterStatus',
  orderMoreCardMachines: 'orderMoreCardMachines',
  orders: 'orders',
  paymentSourcesFilterSelection: 'selectedPaymentSourcesFilter',
  proceededWithSelection: 'proceededWithSelection',
  raisedError: 'raisedError',
  refreshList: 'refresh_list',
  removeContact: 'removeContact',
  removedQuantity: 'removedQuantity',
  requestedResetLink: 'requestedResetLink',
  resetCardFilter: 'resetCardFilter',
  resetCardMachineFilter: 'resetCardMachineFilter',
  resetDateRangeFilter: 'resetDateRangeFilter',
  resetLocationFilter: 'resetLocationFilter',
  resetPaymentSourceFilter: 'resetPaymentSourceFilter',
  resetStatusFilter: 'resetStatusFilter',
  resetTimeRangeFilter: 'resetTimeRangeFilter',
  returnedSearchResult: 'returnedSearchResult',
  savedReference: 'savedReference',
  savedSelection: 'savedSelection',
  saveLocationInfo: 'save_location_info',
  saveTerminalInfo: 'save_terminal_info',
  searchedDeliveryAddress: 'searchedDeliveryAddress',
  searchedDeliveryContact: 'searchedDeliveryContact',
  searchedLocation: 'searchedLocation',
  selectedBillingInvoice: 'selectedBillingInvoice',
  selectedDate: 'selectedDate',
  selectedDeliveryAddress: 'selectedDeliveryAddress',
  selectedDeliveryContact: 'selectedDeliveryContact',
  selectedDoNotRemoveContact: 'selectedDoNotRemoveContact',
  selectedGivenDateFilter: 'selectedGivenDateFilter',
  selectedHelp: 'selectedHelp',
  selectedLink: 'selectedLink',
  selectedLogOut: 'selectedLogOut',
  selectedMenuItem: 'selectedMenuItem',
  selectedOption: 'selectedOption',
  selectedOrderDetails: 'selectedOrderDetails',
  selectedPaymentLink: 'selectedPaymentLink',
  selectedPresetCustomDateRange: 'selectedPresetCustomDateRange',
  selectedPrivacyPolicy: 'selectedPrivacyPolicy',
  selectedQueuesBookingsSignUp: 'clickedSignUp',
  selectedQueuesBookingsSubmit: 'clickedSubmit',
  selectedReason: 'selectedReason',
  selectedRemoveContact: 'selectedRemoveContact',
  selectedTapToPayDevices: 'selectedTapToPayDevices',
  selectedTermsConditions: 'selectedTermsConditions',
  selectedVirtualTerminal: 'selectedVirtualTerminal',
  selectLocation: 'selectLocation',
  setupPayment: 'setupPayment',
  sharedPaymentLinkQRCode: 'sharedPaymentLinkQRCode',
  sharePaymentLink: 'sharedPaymentLink',
  showOnlyActiveCardMachines: 'showOnlyActiveCardMachines',
  sourceFilter: 'filteredSource',
  sourceFilterSelection: 'selectedSourceFilter',
  startedComplianceProcess: 'started_compliance_process',
  statusFilter: 'filteredStatus',
  statusFilterSelection: 'selectedStatusFilter',
  switchedAccount: 'switchedAccount',
  tablePaymentPage: 'tablePaymentPage',
  teamsMember: 'teams_member',
  viewCardholderDetails: 'view_cardholder_details',
  viewedStatus: 'viewed_status',
  viewMoreDetails: 'viewMoreDetails',
  confirmedCloseDevice: 'confirmedCloseDevice',
  clickedCloseDevice: 'clickedCloseDevice',
  clickedRestart: 'clickedRestart',
};

export const AnalyticsPageName = {
  dojoAdvance: 'webAccountDojoAdvance',
  emailReceipt: 'emailReceipt',
  error: 'error',
  overview: 'overview',
  qrCode: 'qrCode',
  permission: 'permission',
  sharePaymentLinkQRCode: 'sharePaymentLinkQRCode',
  applicationStatus: 'applicationStatus',
  receiptSent: 'receiptSent',
  sharePaymentLink: 'sharePaymentLink',
  sale: 'sale',
  refundConfirmationStatus: 'refundConfirmationStatus',
  refundForm: 'refundForm',
  refundConfirm: 'refundConfirm',
  productValueProp: 'productValueProp',
  notFound: 'notFound',
  viewQRCode: 'viewQRCode',
  closeDevice: 'closeDevice',
  deviceRemovedSuccessfully: 'deviceRemovedSuccessfully',
  deviceRemovedUnsuccessfully: 'deviceRemovedUnsuccessfully',
};

export const AnalyticsLabel = {
  PCICompliance: 'PCI Compliance',
  CardMachine: 'Card Machine',
  Insights: 'Insights',
  Help: 'Help',
  PrivacyPolicy: 'Privacy Policy',
  TermsConditions: 'Terms Conditions',
  DojoAdvance: 'Dojo Advance',
  AddTeamMember: 'Add member',
  RemoveTeamMember: 'Remove member',
  buttonCopy: 'Copy',
  removeContact: 'removeContact',
  reviewPurchase: 'reviewPurchase',
  contactRemoved: 'contactRemoved',
};

export const TransactionFiltersAppliedActions = {
  locationIds: 'appliedLocation',
  dateRange: 'appliedDateRange',
  timeRange: 'appliedTimeRange',
  transactionTypes: 'appliedTransactionType',
  outcomes: 'appliedTransactionOutcome',
  schemes: 'appliedCardScheme',
  sourceTypes: 'appliedPaymentMethod',
  cardMachineTerminalIds: 'appliedCardMachines',
  cardTypes: 'appliedCardTypes',
  search: 'searchTransactions',
};

export const AnalyticsBusinessFundingPageName = {
  overview: 'overview',
  verification: 'verification',
  openBanking: 'openBanking',
  openBankingPermission: 'openBankingPermission',
  openBankingSuccessfullyLinked: 'openBankingSuccessfullyLinked',
  openBankingAccountSelection: 'openBankingAccountSelection',
  openBankingFail: 'openBankingFail',
  openBankingSuccess: 'openBankingSuccess',
  fileTable: 'fileTable',
  applicationSubmitted: 'applicationSubmitted',
  permission: 'permission',
  contract: 'contract',
  contractMultipleOwners: 'contractMultipleOwners',
  offerAgreed: 'offerAgreed',
  funded: 'funded',
  applicationFail: 'applicationFail',
  offers: 'offers',
  customiseOffer: 'customiseOffer',
  reviewOffer: 'reviewOffer',
  preApprovedOffers: 'preApprovedOffers',
};

export const AnalyticsBusinessFundingActions = {
  clickedContinue: 'clickedContinue',
  clickedAcceptAndContinue: 'clickedAcceptAndContinue',
  selectedOption: 'selectedOption',
  clickedGetOffers: 'clickedGetOffers',
  clickedBankSearchLink: 'clickedBankSearchLink',
  clickedWithADifferentAccount: 'clickedWithADifferentAccount',
  clickedContinueOnDesktop: 'clickedContinueOnDesktop',
  clickedUploadBankStatementManually: 'clickedUploadBankStatementManually',
  clickedUpload: 'clickedUpload',
  clickedChooseADifferentOffer: 'clickedChooseADifferentOffer',
  clickedAcceptOffer: 'clickedAcceptOffer',
  clickedSignAgreement: 'clickedSignAgreement',
  clickedViewInYouLend: 'clickedViewInYouLend',
  clickedConfirm: 'clickedConfirm',
  clickedBack: 'clickedBack',
  clickedClose: 'clickedClose',
  clickedCancel: 'clickedCancel',
  clickedCancelConfirm: 'clickedCancelConfirm',
  clickedCancelDismiss: 'clickedCancelDismiss',
  clickedLearnMore: 'clickedLearnMore',
};
